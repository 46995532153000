<template>
  <div class="wap-avatar">
    <headerBar
      :title="$t('tou-xiang-0')"
      @back="back"
      :right-text="$t('bao-cun')"
      @detail="save"
    ></headerBar>

    <div class="avatar-box">
      <div
        class="user-header big"
        :class="`header-${i}`"
        v-for="i in 20"
        :key="i"
        @click="changeHeader(i)"
      >
        <i class="el-icon-success" v-if="avatar === i"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      avatar: ''
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    header() {
      return this.$store.state.avatar
    }
  },
  mounted() {
    this.avatar = this.header
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    save() {
      this.$store.state.avatar = this.avatar
      this.back()
    },
    changeHeader(i) {
      this.avatar = i
    }
  }
}
</script>